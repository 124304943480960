import React, { useState } from 'react';
import { Image, Input, Segment, Button, Label, Grid } from 'semantic-ui-react';

const List = () => {
  const [items, setItems] = useState(JSON.parse(localStorage.getItem('items')) || []);

  const updateItems = newItems => {
    localStorage.setItem('items', JSON.stringify(newItems));
    setItems(newItems);
  };

  const getItemIndex = id => items.findIndex(item => item.id === id);

  const handleRemove = id => {
    const pos = getItemIndex(id);
    const newItems = [...items];
    newItems.splice(pos, 1);
    return updateItems(newItems);
  };

  const markComplete = (id, complete) => {
    const pos = getItemIndex(id);
    const item = { ...items[pos], complete };
    const newItems = [...items];
    newItems.splice(pos, 1, item);
    return updateItems(newItems);
  };

  const handleAdd = () => {
    const { value } = document.getElementById('note-input');
    if (value === '') {
      return;
    }
    const item = {
      id: new Date().getTime(),
      name: value,
      complete: false,
      editing: false,
      link: '',
      image: ''
    };
    document.getElementById('note-input').value = '';
    const newItems = items.concat(item);
    updateItems(newItems);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      return handleAdd();
    }
    return null;
  };

  const toggleEditing = (id, editing) => {
    const pos = getItemIndex(id);
    const oldItem = items[pos];
    let { name, description, link } = oldItem;
    if (!editing) {
      // eslint-disable-next-line prefer-destructuring
      name = document.getElementById(`${id}name`).value;
      description = document.getElementById(`${id}description`).value;
      link = document.getElementById(`${id}link`).value;
    }
    const item = { ...oldItem, editing, name, description, link };
    const newItems = [...items];
    newItems.splice(pos, 1, item);
    return updateItems(newItems);
  };

  const handleFile = (e, id) => {
    const pos = getItemIndex(id);
    const reader = new FileReader();
    let image;
    reader.onload = () => {
      image = reader.result;
      const item = { ...items[pos], image };
      const newItems = [...items];
      newItems.splice(pos, 1, item);
      return updateItems(newItems);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const rows = items.map(item => (
    <Segment {...(item.complete ? { color: 'green', inverted: true } : {})} clearing key={`${item.id}`}>
      {item.editing ? (
        <>
          <Grid columns={2} relaxed="very" stackable>
            <Grid.Column>
              <Grid.Row>
                <Label htmlFor={`${item.id}name`}> Name </Label>
                <Input fluid id={`${item.id}name`} size="mini" defaultValue={item.name} />
              </Grid.Row>
              <Grid.Row>
                <Label htmlFor={`${item.id}description`}> description </Label>
                <Input fluid id={`${item.id}description`} size="mini" defaultValue={item.description} />
              </Grid.Row>
              <Grid.Row>
                <Label htmlFor={`${item.id}link`}> Link </Label>
                <Input fluid id={`${item.id}link`} size="mini" defaultValue={item.link} />
              </Grid.Row>
              <Grid.Row>
                <Label htmlFor={`${item.id}image`}> Image </Label>
                <Input onChange={e => handleFile(e, item.id)} id={`${item.id}image`} type="file" transparent />
              </Grid.Row>
            </Grid.Column>
            <Grid.Column>{item.image && <Image bordered src={item.image} size="medium" />}</Grid.Column>
          </Grid>
          <Button onClick={() => toggleEditing(item.id, false)} floated="right" icon="save" color="green" />
        </>
      ) : (
        <>
          <Grid columns={2} relaxed="very" stackable>
            <Grid.Column>
              <Grid.Row>
                <list-item>
                  {item.link ? (
                    <a rel="noopener noreferrer" target="_blank" href={item.link}>
                      {item.name}
                    </a>
                  ) : (
                    item.name
                  )}
                </list-item>
              </Grid.Row>
              <Grid.Row>
                <list-description>{item.description}</list-description>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column>{item.image && <Image bordered src={item.image} size="small" />}</Grid.Column>
          </Grid>
          {item.complete ? (
            <Button onClick={() => markComplete(item.id, false)} floated="right" icon="undo" color="yellow" />
          ) : (
            <Button onClick={() => markComplete(item.id, true)} floated="right" icon="check" color="green" />
          )}
          <Button onClick={() => toggleEditing(item.id, true)} floated="right" icon="pencil" color="blue" />
          <Button onClick={() => handleRemove(item.id)} floated="right" icon="x" color="red" />
        </>
      )}
    </Segment>
  ));

  return (
    <>
      <Input
        id="note-input"
        fluid
        action={{
          onClick: () => handleAdd(),
          primary: true,
          labelPosition: 'right',
          icon: 'add',
          content: 'Add'
        }}
        size="mini"
        placeholder="Item"
        onKeyDown={handleKeyDown}
      />
      <div className="List">{rows}</div>
    </>
  );
};

export default List;
